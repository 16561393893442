body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainContainer {
  background: black;

  overflow: hidden;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.boControl {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.boButton {
  width: 7em;
  height: 2em;

  font-size: 2rem;

  border: none;
  border-radius: 0.5em;
  background-color: #c9f6ff;
  margin-bottom: 1em;
}

.whee {
  color: white;
}

.boButton:hover {
  background-color: #99d4e0;
}

.boButton:active {
  background-color: #749ba3;
}